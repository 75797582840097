'use client';

import React, { useState, useEffect } from 'react';

import Image from 'next/image';
import Link from 'next/link';

import '@STYLE/global.scss';
import KakaoLive from './kakaoLive';


function CustomerSup() {

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (/android|iphone|ipad|ipod/i.test(userAgent)) {
      setIsMobile(true);
    }
  }, []);

  return (
    <section id='csSection' className='csSection'>
      <h2 className='payService__title sectionTitle'>고객지원</h2>
      <div className='csSection__contentBox'>
        <div className='csSection__contentBox-conItem'>
          <Image
            src='/images/ione_cs_01.png'
            alt='문의하기 아이콘'
            width={100}
            height={100}
            priority
          />
          <Link href='/inquiry' className='subMitBtn'>
            <span>문의하기</span>
            <span className='smallText'>문의작성 페이지 이동</span>
          </Link>
          <div className='csBorder' />
        </div>
        <div className='csSection__contentBox-conItem'>
          <Image
            src='/images/ione_cs_02.png'
            alt='상담원 연결 아이콘l'
            width={100}
            height={100}
            priority
          />
          <Link 
            href={isMobile ? 'tel:032-209-1056' : '#'} 
            className='subMitBtn'
            onClick={(e) => {
              if (!isMobile) {
                e.preventDefault();
                alert('이 기능은 모바일 환경에서만 사용 가능합니다.');
              }
            }}
          >
            <span>상담원 연결</span>
            <span className='smallText'>032-209-1056~9</span>
          </Link>
          <div className='csBorder' />
        </div>
        <div className='csSection__contentBox-conItem'>
          <Image
            src='/images/ione_cs_03.png'
            alt='메일 보내기 아이콘'
            width={100}
            height={100}
            priority
          />
          <button className='subMitBtn' onClick={() => alert('페이지 구현 예정')}>
            <span>아이원 파트너스</span>
            <span className='smallText'>가맹점 관리시스템</span>
          </button>
          <div className='csBorder' />
        </div>
        <KakaoLive></KakaoLive>
      </div>
      <span className='endSectionTitle'>
        아이원페이먼트는 항상 고객과의 신뢰를 유지하기 위하여 최선을
        다하겠습니다
      </span>
    </section>
  );
}

export default CustomerSup;
