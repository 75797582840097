'use client';

import { useState, useEffect } from 'react';
import { useResponsiveImage } from '@/public/js/urpimg';
import Link from 'next/link';
import Image from 'next/image';

import '@STYLE/global.scss';

function serviceFlow() {
  const isSmallScreen = useResponsiveImage();

  return (
    <section id='serFlow' className='serFlow'>
      <h2 className='payService__title sectionTitle'>SERVICE FLOW</h2>
      <span className='payService__serviceTitle sectionSubTitle'>
        아이원페이먼트는 안전한 결제시스템 운영을 지원합니다
      </span>
      <div className='serFlowVisual'>
        <Image
          key={isSmallScreen ? 'mobile' : 'desktop'}
          src={
            isSmallScreen
              ? '/images/ione_flow_01M.png'
              : '/images/ione_flow_01P.png'
          }
          alt='Service Flow Visual'
          width={1920}
          height={815}
          className='imageScale'
          id='imageScale'
          priority
        />
      </div>
    </section>
  );
}

export default serviceFlow;
