'use client';

import React, { useState } from 'react';
import Image from 'next/image';
import Link from 'next/link';
// import Script from 'next/script';

import '@STYLE/global.scss';

function OfflinePayment() {
  const [activeTab, setActiveTab] = useState('offline');

  return (
    <section id='tabMenuContainer' className='tabMenuContainer'>
      <div className='tabMenuContainer__inner'>
        <div className='tabMenu'>
          <ul className='tabMenu__tabList'>
            <li
              className={`tabMenu__tabList-item ${activeTab === 'offline' ? 'active' : ''}`}
              onClick={() => setActiveTab('offline')}
            >
              오프라인 간편결제
            </li>
            <li
              className={`tabMenu__tabList-item ${activeTab === 'online' ? 'active' : ''}`}
              onClick={() => setActiveTab('online')}
            >
              온라인 간편결제
            </li>
          </ul>
        </div>

        <div
          className={`paySection ${activeTab === 'offline' ? 'active' : ''}`}
          id='offlinePay'
        >
          <h2 className='payService__title'>I-ONE SERVICE (오프라인)</h2>
          <div className='tabContainer'>
            <div className='tabContainer__box'>
              <div className='tabContainer__box-item'>
                <p>
                  알리페이
                  <span>중국 모바일결제 업계 1위</span>
                </p>
              </div>
            </div>
            <div className='tabContainer__box'>
              <div className='tabContainer__box-item'>
                <p>
                  위챗페이
                  <span>중국 최대메신저 결제서비스</span>
                </p>
              </div>
            </div>
            <div className='tabContainer__box'>
              <div className='tabContainer__box-item'>
                <p>
                  유니온페이
                  <span>중국 최대 카드사 모바일결제</span>
                </p>
              </div>
            </div>
            <div className='tabContainer__box'>
              <div className='tabContainer__box-item'>
                <p>
                  알리페이 플러스
                  <span>글로벌 통합 크로스보더 결제</span>
                </p>
              </div>
            </div>
            <div className='tabContainer__box'>
              <div className='tabContainer__box-item'>
                <p>
                  프롬프트페이
                  <span style={{ display: 'block' }}>태국대표 간편결제</span>
                </p>
              </div>
            </div>
            <div className='tabContainer__box'>
              <div className='tabContainer__box-item'>
                <p>
                  페이나우
                  <span>싱가포르 대표 간편결제</span>
                </p>
              </div>
            </div>
            <div className='tabContainer__box'>
              <div className='tabContainer__box-item'>
                <p>
                  스냅
                  <span style={{ display: 'block' }}>
                    동남아 통합 간편결제{' '}
                  </span>
                </p>
              </div>
            </div>
            <div className='tabContainer__box'>
              <div className='tabContainer__box-item'>
                <p>
                  카카오페이
                  <span style={{ display: 'block' }}>
                    국내 최대 모바일 간편결제
                  </span>
                </p>
              </div>
            </div>
          </div>
          <p className='endDesc'>
            아이원페이먼트는 알리페이 위챗페이등 PG사 연동을 지원합니다
          </p>
        </div>

        <div
          className={`paySectionNext ${activeTab === 'online' ? 'active' : ''}`}
          id='onlinePay'
        >
          <h2 className='payService__title'>I-ONE SERVICE (온라인)</h2>
          <div className='tabContainer'>
            <div className='tabContainer__box'>
              <div className='tabContainer__box-item'>
                <p>
                  알리페이
                  <span>중국 모바일결제 업계 1위</span>
                </p>
              </div>
            </div>
            <div className='tabContainer__box'>
              <div className='tabContainer__box-item'>
                <p>
                  위챗페이
                  <span>중국 최대메신저 결제서비스</span>
                </p>
              </div>
            </div>
            <div className='tabContainer__box'>
              <div className='tabContainer__box-item'>
                <p>
                  유머니
                  <span>독립국가연합 결제서비스</span>
                </p>
              </div>
            </div>
            <div className='tabContainer__box'>
              <div className='tabContainer__box-item'>
                <p>
                  알리페이 플러스
                  <span>글로벌 통합 크로스보더 결제</span>
                </p>
              </div>
            </div>
            <div className='tabContainer__box'>
              <div className='tabContainer__box-item'>
                <p>
                  페이팔
                  <span style={{ display: 'block' }}>
                    글로벌 1위 온라인 결제
                  </span>
                </p>
              </div>
            </div>
          </div>
          <p className='endDesc'>
            아이원페이먼트는 알리페이 위챗페이등 PG사 연동을 지원합니다
          </p>
        </div>
      </div>
    </section>
  );
}

export default OfflinePayment;
