'use client';

import React from 'react';
import Link from 'next/link';
import Image from 'next/image';

import '@STYLE/global.scss';

function Payservice() {
  return (
    <section id='payService' className='payService'>
      <h2 className='payService__title sectionTitle'>I-ONE SERVICE</h2>
      <span className='payService__serviceTitle sectionSubTitle'>
        아이원페이먼트는 다양한 결제 서비스를 제공합니다
      </span>
      <div className='payService__serviceBox'>
        <div className='payService__serviceBox-item'>
          <Link href='/terminal' className='moveBtn'>
            <Image
              src='/images/ione_item_01.jpg'
              alt='단말기 결제 시스템'
              width={366}
              height={400}
            />
            
            <div className='itemOverlay'>
              <p>단말기 결제 시스템</p>
            </div>
          </Link>
        </div>
        <div className='payService__serviceBox-item'>
          <Link href='/terminal' className='moveBtn'>
              <Image
                src='/images/ione_item_02.jpg'
                alt='포스기 결제 시스템'
                width={366}
                height={400}
              />
            <div className='itemOverlay'>
              <p>포스기 결제 시스템</p>
            </div>
          </Link>
        </div>
        <div className='payService__serviceBox-item'>
          <Link href='/terminal/kioskDetail' className='moveBtn'>
            <Image
              src='/images/ione_item_03.jpg'
              alt='키오스크 결제 시스템'
              width={366}
              height={400}
            />
            <div className='itemOverlay'>
              <p>키오스크 결제 시스템</p>
            </div>
          </Link>
        </div>
        <div className='payService__serviceBox-item'>
          <Link href='/#tabMenuContainer' className='moveBtn'>
            <Image
              src='/images/ione_item_04.jpg'
              alt='주차관리 결제 시스템'
              width={366}
              height={400}
            />
            <div className='itemOverlay'>
              <p>글로벌 결제 시스템</p>
            </div>
          </Link>
        </div>
      </div>
    </section>
  );
}

export default Payservice;
