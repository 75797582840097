'use client';

import Script from 'next/script';

import '@STYLE/global.scss';

import SwiperComponent from './_components/SwiperComponent';
import Payservice from './_components/Payservice';
import ServiceFlow from './_components/ServiceFlow';
import SerApply from './_components/SerApply';
import OfflinePayment from './_components/OfflinePay';
import CustomerSup from './_components/CustomerSup';

const customScriptContent = `
console.log('Custom script loaded');
`;

export default function Home() {
  return (
    <main>
      <main
        id='main-wrapper'
        className='mWrapper'
        // style={{ display: show ? 'block' : 'none' }}
      >
        <h2 className='blind'>본문시작</h2>
        <div id='page-wrapper' className='pWrapper'>
          <SwiperComponent />
          <Payservice />
          <ServiceFlow />
          <SerApply />
          <OfflinePayment />
          <CustomerSup />
          {/* <KakaoMap
            apiKey='ac290f4776330c1300022503ff3f9b16'
            containerId='mapContainer'
            latitude={37.5665} // 서울의 위도
            longitude={126.978} // 서울의 경도
            level={3} // 확대 수준
          /> */}
          {/* <div id='map' class='height-md w-100' /> */}
        </div>
      </main>
      <script src='https://unpkg.com/swiper/swiper-bundle.min.js' async />
      <Script
        id='custom-script'
        src='/js/jquery.min.js'
        strategy='afterInteractive'
      >
        {customScriptContent}
      </Script>

      <Script
        id='ui-script'
        dangerouslySetInnerHTML={{
          __html: `
          `,
        }}
      />
    </main>
  );
}
