import React, { useEffect } from 'react';
import Image from 'next/image';

const KakaoLive = () => {
  useEffect(() => {
    // Kakao JavaScript SDK 로드
    const script = document.createElement('script');
    script.src = 'https://t1.kakaocdn.net/kakao_js_sdk/2.1.0/kakao.min.js';
    script.integrity =
      'sha384-dpu02ieKC6NUeKFoGMOKz6102CLEWi9+5RQjWSV0ikYSFFd8M3Wp2reIcquJOemx';
    script.crossOrigin = 'anonymous';
    script.onload = () => {
      // Kakao SDK 초기화
      window.Kakao.init('3e69e3ad541c8133e9faaf65783f4673'); // 여기에 발급받은 JavaScript 키 입력
    };
    document.body.appendChild(script);

    // 클린업: 컴포넌트가 언마운트될 때 스크립트를 제거
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const chatChannel = () => {
    if (window.Kakao) {
      window.Kakao.Channel.chat({
        channelPublicId: '_xlyXgG', // "채널 아이디"를 실제 채널 ID로 대체
      });
    }
  };

  return (
    
  <div className='csSection__contentBox-conItem'>
    <Image
      src='/images/ione_kakao_icon.png'
      alt='실시간 채팅문의 아이콘'
      width={100}
      height={100}
      priority
    />
    <button id='kakaoLiveBtn' className='subMitBtn' onClick={chatChannel}>
      <span>실시간 채팅상담</span> 
      <span className='smallText'>상담 시간 09:00 ~ 17:30 </span>
    </button>
    <div className='csBorder' />
  </div>
  );
};

export default KakaoLive;
