import { useState, useEffect } from 'react';

export function useResponsiveImage() {
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    // 브라우저 환경에서만 실행되도록 보호
    if (typeof window !== 'undefined') {
      const handleResize = () => {
        setIsSmallScreen(window.innerWidth <= 768);
      };

      // 초기 실행 시 화면 크기를 설정
      handleResize();

      // 윈도우 크기 변경 시 리스너 추가
      window.addEventListener('resize', handleResize);

      // 컴포넌트 언마운트 시 리스너 제거
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }
  }, []);

  return isSmallScreen;
}
