'use client';

import Image from 'next/image';
import { useResponsiveImage } from '@/public/js/urpimg';

import '@STYLE/global.scss';

function SerApply() {
  const isSmallScreen = useResponsiveImage();
  return (
    <section id='serApply' className='serApply'>
      <h2 className='payService__title sectionTitle'>Apply SERVICE</h2>
      <span className='payService__serviceTitle sectionSubTitle'>
        아이원페이먼트 신청 및 이용방법
      </span>
      <div className='serFlowVisual'>
        <Image
          key={isSmallScreen ? 'mobile' : 'desktop'}
          src={
            isSmallScreen
              ? '/images/ione_apply_01M.png'
              : '/images/ione_apply_01P.png'
          }
          alt='Service Flow Visual'
          width={1920}
          height={815}
          className='imageScale'
          id='applyImg'
          priority
        />
      </div>
    </section>
  );
}

export default SerApply;
