'use client';

import { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import '@STYLE/swiper.scss';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Pagination, Autoplay } from 'swiper/modules';

import Image from 'next/image';

function MySwiperComponent() {
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 390);
    };
    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <Swiper
      modules={[Pagination, Autoplay]}
      slidesPerView={1}
      // autoplay={{ delay: 7000 }}
      pagination={{ clickable: true }}
      allowTouchMove={false}
      // loop={true}
      className='mainSwiper'
      onInit={() => {}}
    >
      <SwiperSlide className='swiperSlide'>
        <div className='item'>
          <header
            className='imgBg height-hero imgBg-bottom overflow-hidden'
            data-overlay='5'
          >
            <div className='imgBg-holder'>
              <Image
                src={
                  isSmallScreen
                    ? '/images/ione_main_01M.jpg'
                    : '/images/ione_main_01C.jpg'
                }
                alt='mainVisual'
                // layout='responsive'
                width={1920}
                height={815}
                className='imageScale'
                id='imageScale'
                priority
              />
            </div>
            <div class='container visualDescTitle'>
              <div class='visualDescTitle__descBox'>
                <div
                  class='col-lg-12 col-10 visualDescTitle__descBox-viw'
                  data-aos='fade-down'
                  data-aos-duration='1400'
                >
                  <div className='imgHandle' />
                  <h2 class='titleText'>
                    <p>
                      <span className='upText'>G</span>lobal Financial
                    </p>
                    <p>
                      <span className='upText'>C</span>ompany I-One
                    </p>
                    <p>
                      <span className='upText'>W</span>ith You
                    </p>
                    <span className='mainVisualTitle'>
                      전국 가맹점에게 필요한
                      <br /> 카드결제 & 전산시스템 서비스제공!
                    </span>
                  </h2>
                  <p class='text-white'>
                    <span className='subVisualTitle'>
                      아이원페이가 제공합니다
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </header>
        </div>
        <div className='overlayVisual' />
        <Image
          src='/images/ione_phone_mokup.png'
          alt='mainVisual'
          width={570}
          height={706}
          className='imgHandle'
          id='imgHandle'
          priority
        />
      </SwiperSlide>
      <SwiperSlide className='swiperSlide'>
        <div className='item'>
          <header
            className='imgBg height-hero imgBg-bottom overflow-hidden'
            data-overlay='5'
          >
            <div className='imgBg-holder'>
              <Image
                src={
                  isSmallScreen
                    ? '/images/ione_main_02M.jpg'
                    : '/images/ione_main_02C.jpg'
                }
                alt='mainVisual'
                // layout='responsive'
                width={1920}
                height={815}
                className='imageScale'
                id='imageScale'
                priority
              />
            </div>
            <div class='container visualDescTitle'>
              <div class='visualDescTitle__descBox'>
                <div
                  class='col-lg-12 col-10 visualDescTitle__descBox-viw'
                  data-aos='fade-down'
                  data-aos-duration='1400'
                >
                  <div className='imgHandle' />
                  <h2 class='titleText'>
                    <p>
                      <span className='upText'>P</span>artners
                    </p>
                    <p>
                      <span className='upText'>S</span>afe InterLocking
                    </p>
                    <p>
                      <span className='upText'>P</span>rovide
                    </p>
                    <span className='mainVisualTitle'>
                      알리페이 & 위챗페이등
                      <br /> 안전한 연동 시스템등 파트너쉽 서비스를
                    </span>
                  </h2>
                  <p class='text-white'>
                    <span className='subVisualTitle'>
                      아이원페이로 제공합니다.
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </header>
        </div>
        <div className='overlayVisual' />
      </SwiperSlide>
      <SwiperSlide className='swiperSlide'>
        <div className='item'>
          <header
            className='imgBg height-hero imgBg-bottom overflow-hidden'
            data-overlay='5'
          >
            <div className='imgBg-holder'>
              <Image
                src={
                  isSmallScreen
                    ? '/images/ione_main_03M.jpg'
                    : '/images/ione_main_03C.jpg'
                }
                alt='mainVisual'
                // layout='responsive'
                width={1920}
                height={815}
                className='imageScale'
                id='imageScale'
                priority
              />
            </div>
            <div class='container visualDescTitle'>
              <div class='visualDescTitle__descBox'>
                <div
                  class='col-lg-12 col-10 visualDescTitle__descBox-viw'
                  data-aos='fade-down'
                  data-aos-duration='1400'
                >
                  <div className='imgHandle' />
                  <h2 class='titleText'>
                    <p>
                      <span className='upText'>C</span>onnect
                    </p>
                    <p>
                      <span className='upText'>T</span>he World With
                    </p>
                    <p>
                      <span className='upText'>F</span>inance
                    </p>
                    <span className='mainVisualTitle'>
                      전자상거래 최대 결제서비스
                      <br />
                      알리페이, 위챗페이를 한번에
                    </span>
                  </h2>
                  <p class='text-white'>
                    <span className='subVisualTitle'>
                      아이원페이가 제공합니다.
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </header>
        </div>
        <div className='overlayVisual' />
      </SwiperSlide>
    </Swiper>
  );
}

export default MySwiperComponent;
